import qs from "qs";
import moment from "moment";
import tableConfigs from "./tableConfigs";
import axios from "@/axiosClient.js";
import simpleAxios from "axios";
import store from "@/store";
import { Debugout } from "debugout.js";

const bugout = new Debugout({ useTimestamps: true, logFilename: "ninja-logs.txt", useLocalStorage: true, autoTrim: true, maxLines: 30000 });
bugout.log("Debugout loaded");

moment.suppressDeprecationWarnings = true;
moment.locale(store.state.locale);

// API LINKS

// const apiMain = "http://localhost:3000";
const apiMain = "https://ninja-lims-350520.ew.r.appspot.com";

// const apiAuth = "http://localhost:3001";
const apiAuth = "https://auth-dot-ninja-lims-350520.ew.r.appspot.com";

// const apiReports = "http://localhost:3003";
const apiReports = "https://report-dot-ninja-lims-350520.ew.r.appspot.com";

const apiTeleservices = "https://teleservices-dev.lims.ninja";
// const apiTeleservices = "https://teleservices.lims.ninja";

const apiScan = "http://localhost:8000";

// **************** BUILDING AXIOS REQUESTS ****************

const REQUESTS = { GET: "get", POST: "post", PUT: "put" };

const buildRequest = function (type, endpoint, data = {}, requiresAuth = false, upload = false) {
  const headers = {};

  if (requiresAuth) {
    headers.Authorization = "Bearer " + store.state.access_token;
    headers.uid = store.state.uid;
    headers.authmachineid = store.state.auth_machine_id;
  }

  headers["Content-Type"] = upload ? "multipart/form-data" : "application/x-www-form-urlencoded";

  if (type == REQUESTS.GET) {
    return {
      method: type,
      url: endpoint,
      headers,
      params: data,
    };
  } else if (type == REQUESTS.POST || type == REQUESTS.PUT) {
    return {
      method: type,
      url: endpoint,
      headers,
      data: upload ? data : qs.stringify(data),
    };
  }

  return null;
};

// **************** GET REQUESTS ****************

async function getDropdownContent(content) {
  // Attach the handler if unattached
  this.errorHandler = this.errorHandler || errorHandler;

  // Form the request URL
  let apiRoute = apiMain + tableConfigs[content]["getAll"];

  const config = buildRequest(
    REQUESTS.GET,
    apiRoute,
    {
      search: "",
      sort_by: content == "collectors" ? "collector_owner" : tableConfigs[content].sortBy[1],
      sort_order: content == "collectors" ? "ASC" : tableConfigs[content].sortBy[2],
      number_of_records: 2147483647,
      page_number: 1,
    },
    true
  );

  await axios(config)
    .then((response) => {
      this[tableConfigs[content].save] = response.data.data;
    })
    .catch((error) => {
      this.errorHandler(error);
    });
}

//   GET ALL CONTENT - table value is true iff using function to get values for table - otherwise just leave blank. ForFilter is used to differentiate requests from sorting on table page.
async function getTableContent(content, forFilter, dynFilterOpts = null, staticFilterOpts = null) {
  // Attach the handler if unattached
  this.errorHandler = this.errorHandler || errorHandler;
  this.$Progress.start();

  // PUT FILTERS INTO ARRAY FORMAT
  var filterColumn = [];
  var filterValues = [];
  if (dynFilterOpts !== null || staticFilterOpts !== null) {
    var filters = { ...dynFilterOpts };

    // APPEND THE STATIC FILTERS
    for (let f of staticFilterOpts) {
      if (filters[f.columnName] !== undefined) {
        filters[f.columnName].push(f.value);
      } else {
        filters[f.columnName] = [f.value];
      }
    }

    // CONVERT THE OPTIONS TO ORDERED ARRAYS
    for (let colName in filters) {
      if (filters[colName][0] !== undefined) {
        filterColumn.push(colName);
        filterValues.push(filters[colName]);
      }
    }
  }

  var additionalKeys = {};
  if (content === "records") {
    if (this.filterDateFrom && this.filterDateTo) {
      additionalKeys.filter_date_from = this.filterDateFrom;
      additionalKeys.filter_date_to = this.filterDateTo;
    }
  }

  this.sortArr = forFilter ? this.sortArr || tableConfigs[content]["sortBy"] : tableConfigs[content]["sortBy"];

  const apiRoute = apiMain + tableConfigs[content]["getAll"];
  const config = buildRequest(
    REQUESTS.GET,
    apiRoute,
    {
      search: this.searchQuery,
      sort_by: this.sortArr[1],
      sort_order: this.sortArr[2],
      number_of_records: this.sortNumber,
      page_number: this.pageNumber,
      filter_column: filterColumn,
      filter_value: filterValues,
      ...additionalKeys,
    },
    true
  );

  await axios(config)
    .then((response) => {
      // Clear exports and deletes
      this.exportDeleteArr = [];
      this.deleteData = false;
      this.exportData = false;

      if (content === "users") {
        this.tableContents = response.data;
      } else {
        this.numberOfRows = response.data.number_of_rows;
        this.numberOfPages = response.data.number_of_pages;

        content === "clinics"
          ? (this.tableContents = response.data.data.filter((x) => {
            return x.clinic_visible === true;
          }))
          : (this.tableContents = response.data.data);
      }

      this.$Progress.finish();
    })
    .catch((error) => {
      this.errorHandler(error);
    });
}

function formatPossiblyNullDate(date) {
  return date ? moment(date).format("YYYY-MM-DD") : date;
}

function tsWithTzToDatetimeLocal(date, format=false) {
  return date ? moment(date).utc().local().format(format ? format : "YYYY-MM-DDTHH:mm") : date;
}

//   GET SINGLE CONTENT BY ID
async function getSingle(id, content, returnItem = false) {
  // Attach the handler if unattached
  if (!this.errorHandler) this.errorHandler = errorHandler;
  this.$Progress.start();
  const idObj = {};
  let apiRoute = apiMain + tableConfigs[content]["getSingle"];

  idObj[tableConfigs[content]["editId"]] = id;

  const config = buildRequest(REQUESTS.GET, apiRoute, idObj, true);

  return await axios(config)
    .then((response) => {
      if (returnItem) {
        return response.data[0];
      } else {
        if (content === "techniques") {
          this.formData = response.data.sample[0];
        } else {
          this.formData = response.data[0];
        }

        // TODO GET RID OF THIS
        if (this.formData.nabm_last_eeet_date) {
          this.formData.nabm_last_eeet_date = formatPossiblyNullDate(this.formData.nabm_last_eeet_date);
        }
        if (this.formData.collector_obsolete_from) {
          this.formData.collector_obsolete_from = formatPossiblyNullDate(this.formData.collector_obsolete_from);
        }
        if (this.formData.patient_dob) {
          this.formData.patient_dob = formatPossiblyNullDate(this.formData.patient_dob);
        }
        if (this.formData.patient_insurance_from) {
          this.formData.patient_insurance_from = formatPossiblyNullDate(this.formData.patient_insurance_from);
        }
        if (this.formData.patient_insurance_to) {
          this.formData.patient_insurance_to = formatPossiblyNullDate(this.formData.patient_insurance_to);
        }
        if (this.formData.record_prescription_date) {
          this.formData.record_prescription_date = formatPossiblyNullDate(this.formData.record_prescription_date);
        }
      }
      this.$Progress.finish();
    })
    .catch((error) => {
      this.errorHandler(error);
    });
}

function getDuplicates(ids, content) {
  // Attach the handler if unattached
  if (!this.errorHandler) this.errorHandler = errorHandler;

  const apiRoute = apiMain + tableConfigs[content]["getSingle"];

  // Use map to create an array of promises that call the getSingle function for each ID
  const promises = ids.map((id) => {
    const idObj = { [tableConfigs[content]["editId"]]: id };
    const config = buildRequest(REQUESTS.GET, apiRoute, idObj, true);
    return axios(config);
  });

  // Use Promise.all to wait for all the promises to complete
  return Promise.all(promises)
    .then((responses) => {
      // Extract the first object in the response data array and return as an array of results
      const results = responses.map((response) => {
        return response.data[0];
      });

      return results;
    })
    .catch((error) => {
      this.errorHandler(error);
      // Propagate the error so it can be caught by the calling function
      throw error;
    });
}

// **************** PUT REQUESTS ****************

//   UPDATE ITEM - formData is Object

async function updateItem(content, formData) {
  // Attach the handler if unattached
  if (!this.errorHandler) this.errorHandler = errorHandler;

  this.$Progress.start();

  const formDataSanitized = {};

  for (const property in formData) {
    if (formData[property] !== null) {
      formDataSanitized[property] = formData[property];
    }
  }

  let apiRoute = apiMain + tableConfigs[content]["update"];

  switch (content) {
    case "nabms":
      delete formData.nabm_creation_date;
      break;

    case "prescribers":
    case "patients":
    case "clinics":
    case "collectors":
      const prefix = content.slice(0, -1); // Remove 's' at the end
      var joinedNumber = "";
      joinedNumber += formDataSanitized[`${prefix}_phone_country_code`] ? formDataSanitized[`${prefix}_phone_country_code`] : "";
      joinedNumber += formDataSanitized[`${prefix}_phone_num`] ? formDataSanitized[`${prefix}_phone_num`] : "";
      delete formDataSanitized[`${prefix}_phone_num`];
      delete formDataSanitized[`${prefix}_phone_country_code`];
      if (joinedNumber != "") {
        formDataSanitized[`${prefix}_phone_num`] = joinedNumber;
      }
      break;
  }

  const config = buildRequest(REQUESTS.PUT, apiRoute, formDataSanitized, true);

  const result = await axios(config)
    .then((data) => {
      this.successPop("toasts.successUpdate", data.config.url.split("/").at(-1));
      if (content === "parameters") {
        this.goToPage(tableConfigs[content]["tableUrl"]);
      }
      return data;
    })
    .catch((error) => {
      this.errorHandler(error);
    });

  this.$Progress.finish();
  return result;
}

// **************** POST REQUESTS ****************

//   POST ITEM - formData is Object

async function postItem(content, formData, userType = "Internal") {
  // Attach the handler if unattached
  if (!this.errorHandler) this.errorHandler = errorHandler;

  this.$Progress.start();

  const formDataSanitized = {};

  for (const property in formData) {
    if (formData[property] !== "" && formData[property] !== null) {
      formDataSanitized[property] = formData[property];
    }
  }

  switch (content) {
    case "parameters":
      formDataSanitized["parameter_hidden"] = false;
      break;

    case "prescribers":
    case "patients":
    case "clinics":
    case "collectors":
      const prefix = content.slice(0, -1); // Remove 's' at the end
      var joinedNumber = "";
      joinedNumber += formDataSanitized[`${prefix}_phone_country_code`] ? formDataSanitized[`${prefix}_phone_country_code`] : "";
      joinedNumber += formDataSanitized[`${prefix}_phone_num`] ? formDataSanitized[`${prefix}_phone_num`] : "";
      delete formDataSanitized[`${prefix}_phone_num`];
      delete formDataSanitized[`${prefix}_phone_country_code`];
      if (joinedNumber != "") {
        formDataSanitized[`${prefix}_phone_num`] = joinedNumber;
      }
      break;
  }

  var apiRoute = apiMain + tableConfigs[content]["add"];
  if (content === "users") {
    switch (userType) {
      case "Internal":
        apiRoute = apiAuth + tableConfigs[content]["add"];
        break;
      case "External":
        apiRoute = apiAuth + tableConfigs[content]["addExternal"];
        break;
      case "Prescriber":
        apiRoute = `${apiAuth}/add-new-prescriber-user`;
        break;
    }
  }

  const config = buildRequest(REQUESTS.POST, apiRoute, formDataSanitized, true);

  const result = await axios(config)
    .then((response) => {
      this.successPop("toasts.successAdd", response.config.url.split("/").at(-1));
      if (content === "parameters") {
        this.goToPage(tableConfigs[content]["tableUrl"]);
      }

      return response;
    })
    .catch((error) => {
      this.errorHandler(error);
    });

  this.$Progress.finish();
  if (!result) return {};
  return result;
}

// MISC FUNCTIONS (IF YOU WANT $Progress TO WORK, MUST BE ASYNC FUNCTION)

const downloadPdf = function (filename, data) {
  var a = document.createElement("a");
  a.href = "data:application/pdf;base64," + data;
  a.download = filename + ".pdf";
  a.click();
  a.remove();
};

async function globalGetCurrentUser() {
  const apiRoute = apiMain + "/get-user-data";
  const config = buildRequest(REQUESTS.GET, apiRoute, {}, true);

  return await axios(config)
    .then((response) => {
      this.$Progress.finish();
      return response.data.user;
    })
    .catch((error) => {
      this.errorHandler(error);
      return {};
    });
}


async function globalCreateCIQRecord(machine_id) {
  const apiRoute = apiMain + "/create-ciq-record";
  const payload = {
    machine_id,
  };
  const config = buildRequest(REQUESTS.POST, apiRoute, payload, true);

  return await axios(config)
    .then((response) => {
      this.$Progress.finish();
      this.successPop("toasts.recordAdded", response.config.url.split("/").at(-1));
      return response.data;
    })
    .catch((error) => {
      this.errorHandler(error);
      return {};
    });
}


async function globalGetGovIns(patient) {
  // Attach the handler if unattached
  if (!this.errorHandler) this.errorHandler = errorHandler;

  this.$Progress.start();

  const route = `${apiMain}/get-ins-from-identity-information`;
  const payload = {
    last_name: patient.patient_surname,
    first_name: patient.patient_name,
    sex: patient.patient_gender,
    birthday: patient.patient_dob,
    birth_place: patient.patient_birthplace,
  };
  const config = buildRequest(REQUESTS.GET, route, payload, true);

  return await axios(config)
    .then((res) => {
      this.$Progress.finish();
      if (res.data.i_insIdentityResult) {
        // No identity found
        if (res.data.i_insIdentityResult === 1) {
          this.warningPop("toasts.noInsIdentity", res.config.url.split("/").at(-1));
          return false;
        }

        // One identities found
        else if (res.data.i_insIdentityResult === 2) {
          // INS-NIA
          if (res.data.Identity.Ins.s_oid === "1.2.250.1.213.1.4.9") {
            this.warningPop("toasts.insNIA", res.config.url.split("/").at(-1));
          }

          this.successPop("toasts.getINS", res.config.url.split("/").at(-1));
          return res.data.Identity;
        }

        // Multiple identities found
        else if (res.data.i_insIdentityResult === 3) {
          this.warningPop("toasts.multipleInsIdentity", res.config.url.split("/").at(-1));
          return false;
        }
      }
      return false;
    })
    .catch((err) => {
      this.errorHandler(err, true);
    });
}

async function globalPrintPassword(record) {
  // Attach the handler if unattached
  if (!this.errorHandler) this.errorHandler = errorHandler;

  this.$Progress.start();

  const endPoint = "http://localhost:3540/print-password";
  const payload = {
    id: record.record_id,
    password: record.record_password,
  };
  const config = buildRequest(REQUESTS.GET, endPoint, payload);

  await axios(config)
    .then((res) => {
      this.$Progress.finish();
      this.successPop("toasts.printSuccess", res.config.url.split("/").at(-1));
    })
    .catch((error) => {
      this.errorHandler(error);
    });
}

async function globalGetRecordTrace(record_id) {
  // Attach the handler if unattached
  if (!this.errorHandler) this.errorHandler = errorHandler;

  this.$Progress.start();

  const endPoint = apiMain + "/get-record-trace";
  const payload = {
    record_id: record_id,
  };
  const config = buildRequest(REQUESTS.GET, endPoint, payload, true);

  return await axios(config)
    .then((res) => {
      this.$Progress.finish();
      return res.data;
    })
    .catch((error) => {
      this.errorHandler(error);
      return {};
    });
}


async function globalUpdateRecordComments(record_id, record_internal_notes) {
  // Attach the handler if unattached
  if (!this.errorHandler) this.errorHandler = errorHandler;

  this.$Progress.start();

  const route = `${apiMain}/update-record-comments`;
  const payload = {
    record_id,
    record_internal_notes,
  }
  const config = buildRequest(REQUESTS.PUT, route, payload, true);

  return await axios(config)
    .then((res) => {
      this.$Progress.finish();
      this.successPop("toasts.successUpdate", res.config.url.split("/").at(-1));
      return res.data;
    })
    .catch((error) => {
      this.errorHandler(error);
      return {};
    });
}


async function globalAnalysisRequestForm(record) {
  // Attach the handler if unattached
  if (!this.errorHandler) this.errorHandler = errorHandler;

  // GET RECORD SAMPLES
  this.$Progress.start();

  const endPoint = apiReports + "/get-external-request-form";
  const config = buildRequest(REQUESTS.GET, endPoint, { record_id: record.record_id }, true);

  await axios(config)
    .then((response) => {
      if (response.data != "SKIP DOWNLOAD") {
        downloadPdf(`${record.record_id} - ${record.patient_surname.toUpperCase()} ${record.patient_name} - BON DE DEMANDE D'ANALYSE`, response.data);
        this.successPop("toasts.downloadSuccess", response.config.url.split("/").at(-1));
      }
      this.$Progress.finish();
    })
    .catch((error) => {
      this.errorHandler(error);
    });
}


async function globalGetCerfa(record) {

  // Attach the handler if unattached
  if (!this.errorHandler) this.errorHandler = errorHandler;

  this.$Progress.start();

  const endPoint = apiReports + "/get-cerfa";
  const config = buildRequest(
    REQUESTS.GET,
    endPoint,
    { record_id: record.record_id },
    true
  );

  await axios(config)
    .then((response) => {
      downloadPdf(
        `${record.record_id} - ${record.patient_surname.toUpperCase()} ${record.patient_name} - FEUILLE D'HONORAIRES`,
        response.data
      );
      this.$Progress.finish();
      this.successPop("toasts.downloadSuccess", response.config.url.split("/").at(-1));
    })
    .catch((err) => {
      this.errorHandler(err);
    });
}


async function globalScanDocument() {
  // Attach the handler if unattached
  if (!this.errorHandler) this.errorHandler = errorHandler;

  // Do the scan
  this.$Progress.start();
  const endPoint = apiScan + "/scan";
  const config = buildRequest(REQUESTS.GET, endPoint);

  return await simpleAxios(config)
    .then((res) => {
      this.$Progress.finish();
      return res.data;
    })
    .catch((err) => {
      this.errorHandler(err);
      return {};
    });
}


async function globalPrintBarcode(record) {
  // Attach the handler if unattached
  if (!this.errorHandler) this.errorHandler = errorHandler;

  // GET RECORD SAMPLES
  this.$Progress.start();
  var allSamples = await Promise.all(
    record.record_samples.map(async (sample) => {
      const apiRoute = `${apiMain}/get-sample`;
      const payload = { sample_id: sample };
      const config = buildRequest(REQUESTS.GET, apiRoute, payload, true);

      return await axios(config)
        .then((res) => {
          return {
            sample_id: res.data.sample[0].sample_id,
            sample_type: res.data.sample[0].sample_type_name,
            sample_matrix: res.data.sample[0].matrix_name,
          };
        })
        .catch((err) => {
          this.errorHandler(err);
        });
    })
  );

  const endPoint = "http://localhost:3540/print-barcode";
  const payload = {
    name: record.patient_surname + " " + record.patient_name,
    dob: moment(record.patient_dob).format("DDMMYYYY") + " " + record.patient_gender,
    barcode: record.record_id,
    samples: allSamples,
    amount: record.record_samples.length,
  };
  const config = buildRequest(REQUESTS.POST, endPoint, payload);

  await axios(config)
    .then((res) => {
      this.$Progress.finish();
      this.successPop("toasts.printSuccess", res.config.url.split("/").at(-1));
    })
    .catch((error) => {
      this.errorHandler(error);
    });
}

async function globalGetPrescription(record, printResult = false) {
  // Attach the handler if unattached
  if (!this.errorHandler) this.errorHandler = errorHandler;

  // GET RECORD SAMPLES
  this.$Progress.start();
  const endPoint = apiReports + "/get-prescription";
  const config = buildRequest(REQUESTS.GET, endPoint, { record_id: record.record_id }, true);

  await axios(config)
    .then((res) => {
      this.$Progress.finish();
      if (printResult) {
        console.log("printing...");
      } else {
        downloadPdf(`${record.record_id} - ${record.patient_surname.toUpperCase()} ${record.patient_name} - PRESCRIPTION`, res.data);
        this.successPop("toasts.downloadSuccess", res.config.url.split("/").at(-1));
      }
    })
    .catch((err) => {
      this.errorHandler(err);
    });
}

async function globalPrintExternalBarcode(record) {
  // Attach the handler if unattached
  if (!this.errorHandler) this.errorHandler = errorHandler;

  const endPoint = "http://localhost:3540/print-barcode-external";
  const payload = {
    name: record.patient_surname + " " + record.patient_name,
    dob: moment(record.patient_dob).format("DDMMYYYY") + " " + record.patient_gender,
    barcode: record.record_id,
  };
  const config = buildRequest(REQUESTS.POST, endPoint, payload);

  await axios(config)
    .then((res) => {
      this.$Progress.finish();
      this.successPop("toasts.printSuccess", res.config.url.split("/").at(-1));
    })
    .catch((error) => {
      this.errorHandler(error);
    });
}

async function globalScanPrescription(record_id) {
  // Attach the handler if unattached
  if (!this.errorHandler) this.errorHandler = errorHandler;

  // Build the request
  this.$Progress.start();
  const endPoint = "http://localhost:3540/scan-prescription";
  const payload = {
    name: record_id,
  };
  const config = buildRequest(REQUESTS.GET, endPoint, payload);

  await axios(config)
    .then((res) => {
      this.$Progress.finish();
      this.successPop("toasts.printSuccess", res.config.url.split("/").at(-1));
    })
    .catch((error) => {
      this.errorHandler(error);
    });
}

async function passwordReset(resetId, newPassword) {
  // Attach the handler if unattached
  if (!this.errorHandler) this.errorHandler = errorHandler;

  const resetPasswordData = {
    reset_password_link: resetId,
    new_password: newPassword,
  };

  const apiResetPassword = apiMain + "/reset-password";

  const config = buildRequest(REQUESTS.PUT, apiResetPassword, resetPasswordData);

  await axios(config)
    .then((res) => {
      this.successPop("toasts.success", res.config.url.split("/").at(-1));
      this.goToPage("");
    })
    .catch((error) => {
      this.errorHandler(error);
    });
}

// Machine ID Generator

const machineIdGen = function () {
  return Date.now() + Math.random().toString(32).substring(2, 8);
};

async function updateSampleStatus(id, requestedSampleStatus, currentSampleStatus, force_save = false) {

  // Attach the error handler if unattached
  if (!this.errorHandler) this.errorHandler = errorHandler;

  this.$Progress.start();
  const updateSampleApi = apiMain + "/update-sample-status";

  if (requestedSampleStatus <= currentSampleStatus) return false;

  const updateStatusObj = {
    sample_id: id,
    sample_status: requestedSampleStatus,
    force_save,
  };

  const config = buildRequest(REQUESTS.PUT, updateSampleApi, updateStatusObj, true);

  return await axios(config)
    .then((res) => {
      this.successPop("toasts.sampleUpdated", res.config.url.split("/").at(-1));
      this.$Progress.finish();
      return true;
    })
    .catch((error) => {

      if (error.request.status == 403) {
        this.$Progress.finish();
        return error.response.data;
      } else {
        this.errorHandler(error);
      }

      return false;
    });
};

// **************** ERROR HANDLER ****************

function errorHandler(error, warning = false) {
  this.$Progress.fail();

  var routeUrl;
  var keyExists = false;
  var key;
  try {
    // Debug
    key = `ems.${error.response.data}`;

    // Check if the key exists
    keyExists = this.$t(key) != key;

    // Get url
    routeUrl = error.config.url.split("/").at(-1);
  } catch (e) {
    console.log("Error in error handler", e);
    routeUrl = "";
  }

  if (keyExists) {
    if (warning) this.warningPop(key, routeUrl);
    else this.errorPop(key, routeUrl);
  } else {
    console.log(error);
    this.errorPop("toasts.unexpectedError", routeUrl);
  }

  return;
}

const adminKey = {
  nabms: "admin_nabms",
  patients: "admin_patients",
  records: "admin_records",
  export: "admin_patients",
  prescribers: "admin_prescribers",
  analyses: "admin_analyses",
  parameters: "admin_parameters",
  techniques: "admin_samples",
  sample_types: "admin_sample_types",
  machines: "admin_machines",
  families: "admin_families",
  matrices: "admin_matrices",
  users: "admin_access",
  clinics: "admin_clinics",
  collectors: "admin_collectors",
  dashboard: "admin_records",
};

export {
  getTableContent,
  updateItem,
  postItem,
  getSingle,
  buildRequest,
  machineIdGen,
  REQUESTS,
  passwordReset,
  apiMain,
  apiAuth,
  apiReports,
  errorHandler,
  updateSampleStatus,
  adminKey,
  getDuplicates,
  downloadPdf,
  getDropdownContent,
  formatPossiblyNullDate,
  tsWithTzToDatetimeLocal,
  globalScanDocument,
  globalPrintBarcode,
  globalGetCerfa,
  globalScanPrescription,
  globalPrintPassword,
  globalAnalysisRequestForm,
  globalGetGovIns,
  globalPrintExternalBarcode,
  globalGetPrescription,
  globalUpdateRecordComments,
  globalGetCurrentUser,
  globalCreateCIQRecord,
  globalGetRecordTrace,
  apiTeleservices,
  bugout,
};
